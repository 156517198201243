<template>
  <div class="company-logo">
    <n-loader class="logo-loader" :loading="$var('load')" />
    <div class="logo-wrap">
      <div class="logo">
        <img v-show="company.logo?.length !== 0" alt="" :src="company.logo">
      </div>
      <div v-if="route === 'profile'" class="logout" @click="logout">
        <n-icon icon="logout" />
      </div>
      <div v-if="backPath?.backRoute" class="back-route" @click="$router.push({name: backPath.backRoute})">
        <n-icon icon="backRow" />
      </div>
    </div>
  </div>
</template>

<script>
import { PushNotifications, } from '@capacitor/push-notifications'
import { Capacitor, } from '@capacitor/core'
import { FCM, } from '@capacitor-community/fcm'

export default {
  name: 'Index',
  data() {
    return {
      company: $app.auth.user().company,
    }
  },
  computed: {
    route() {
      return this.$route.name
    },
    backPath() {
      return $app.router.current().route
    },
  },
  created() {
    this.load()
  },
  methods: {
    logout() {
      this.$var('load', true)
      if ($n.notifyAvailable()) {
        $api.fcmPush.deleteToken({ token: localStorage.getItem('fcmToken'), }).then((res) => {
          $app.auth.logout()
          PushNotifications.removeAllListeners()
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        $app.auth.logout()
      }
    },
    load() {
      $app.store.action('app.updateUser').finally(() => {
        this.company = $app.auth.user().company
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.company-logo{
  position: fixed;
  left: 0;
  right: 0;
  top: env(safe-area-inset-top);
  background-color: #F5F5FA;
  height: 100px;
  z-index: 11;
  .logo-loader {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  &::after{
    content: '';
    position: absolute;
    background-color: #F5F5FA;
    left: 0;
    right: 0;
    height: 100%;
    top: -100%;
  }

  .back-route {
    position: absolute;
    top: 25px;
    bottom: 0;
    left: 25px;
  }
  .logo-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100px;
    .logout {
      position: absolute;
      top: 25px;
      bottom: 0;
      right: 25px;
    }
  }
  .logo {
    width: 150px;
    height: 55px;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
