/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'new/auth/login', ]).with('company').data(data),
    info: () => $app.api.get([ 'auth/info', ]),
    
    check: (data) => $app.api.post([ 'new/auth/check', ]).data(data),
    sendCode: (data) => $app.api.post([ 'new/auth/send-code', ]).data(data),
    checkCode: (data) => $app.api.post('new/auth/check-code').data(data),
    register: (data) => $app.api.patch([ 'new/auth/register', ]).with('company').data(data),
    
    recoverCheck: (data) => $app.api.post([ 'new/auth/recover/check', ]).data(data),
    recover: (data) => $app.api.patch('new/auth/recover').data(data),
    
    deleteAcc: (id) => $app.api.post([ 'users*/delete-acc', id, ]),
  }


  fcmPush = {
    save: (data) => $app.api.post('device/save').data(data),
    deleteToken: (data) => $app.api.delete('device/delete').data(data),
  }

  user = {
    editPass: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
  }
  
  visits = {
    get: () => $app.api.get([ 'visits', ]),
    create: (companyId, branchId, data) => $app.api.post([ 'companies*/branches*/visits', companyId, branchId, ]).data(data),
    offline: (companyId, data) => $app.api.post([ 'companies*/visits-offline', companyId, ]).data(data),
  }

  checklists = {
    get: (companyId, userId) => $app.api.get([ 'companies*/checklists/users*', companyId, userId, ]),
    getOne: (companyId, checklistId) => $app.api.get([ 'companies*/checklists*', companyId, checklistId, ]),
    stats: (checklistId, userId) => $app.api.get([ 'checklists*/users*/userscheckpoints', checklistId, userId, ]),
  }

  company = {
    getActivePeople: (companyId, data) => $app.api.post([ 'companies*/active-people', companyId, ]).data(data),
    getDepartments: (companyId, id) => $app.api.get([ 'companies*/departments*', companyId, id, ]),
    getBranches: (companyId, id) => $app.api.get([ 'companies*/branches*', companyId, id, ]),
    getOrganizations: (companyId, id) => $app.api.get([ 'companies*/organizations*', companyId, id, ]),
  }
}
