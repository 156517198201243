<template>
  <div class="c-modal-message">
    <div class="message">
      <div class="message-container">
        <div class="text">
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
        </div>
        <div class="buttons">
          <n-button flat class="modal-button agree" @click="agree">Да</n-button>
          <n-button flat class="modal-button cancel" @click="close">Нет</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CModalMessage',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    agree() {
      this.$emit('update:agree', true)
      this.close()
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style scoped lang="scss">
.c-modal-message {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1020;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  align-items: center;
  justify-content: center;
  .message {
    position: absolute;
    display: flex;
    background-color: #F5F5F9;
    border-radius: 16px;
    width: 335px;
    .message-container {
      margin: 16px;
      .text {
        h3 {
          margin: 0 0 4px 0;
          padding: 0;
          font-size: 1.20em;
          color: #000000;
          text-align: center;
        }
        p {
          min-height: 24px;
          margin: 0 0 12px 0;
          padding: 0;
          font-size: 1.05em;
          color: #6D6F7B;
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        .modal-button {
          width: 145px;
          height: 45px;
          background-color: #F5F5F9;
          box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5),
          5px 5px 10px rgba(170, 170, 204, 0.25), 10px 10px 20px rgba(170, 170, 204, 0.5), -10px -10px 20px #FFFFFF;
          border-radius: 8px;
          font-weight: bold;
          font-size: 1.05em;
          &.agree {
            color: #FF4B33;
            margin-right: 12px;
          }
          &.cancel {
            color: #000000;
          }
        }
      }
    }
  }
}
.close-modal {
  display: none;
}
</style>
