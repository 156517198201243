<template>
  <div class="app-bar">
    <div class="go-back" @click="$router.push({name: route})">
      <n-icon icon="backRow" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    route: {
      type: String,
      required: true,
    },
  },
  computed: {},
}
</script>

<style scoped lang="scss">
.app-bar{
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  top: env(safe-area-inset-top);
  .go-back {
    padding: 30px;
  }
}
</style>
