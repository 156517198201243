export default {
  app: {
    state: {
      buffer: [],
      imei: null,
    },
    getters: {
      buffer(state) {
        return state.buffer
      },
      imei(state) {
        return state.imei
      },
    },
    mutations: {
      buffer(state, value) {
        state.buffer.push(value)
      },
      imei(state, value) {
        state.imei = value
      },
    },
    namespaced: true,
    actions: {
      setBuffer({ state, commit, }, value) {
        value = {
          ...value,
          createdAt: $app.date.format(new Date(), 'datetime'),
        }
        commit('buffer', value)
      },
      setImei({ state, commit, }, value) {
        commit('imei', value)
      },
      sendBuffer({ state, commit, }) {
        return $api.visits.offline($app.auth.user().companyId, {
          visits: state.buffer,
        }).then((response) => {
          state.buffer = []
        })
      },
      updateUser({ state, commit, }, data = {}) {
        let query = ''
        console.log(data)
        if (Object.keys(data).length > 0) {
          query = 'year='+data.year
        }
        return $api.auth.info().with('branches').with('workDays')
          .with('company').query(query).with('department').then((response) => {
            $app.auth.user(response.data.content.user)
          })
      },
    },
  },
}
