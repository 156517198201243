<template>
  <div class="c-code-input">
    <div>
      <div class="code-inputs" @click="setFocus">
        <div class="number-place">{{ code[0] }}</div>
        <div class="number-place">{{ code[1] }}</div>
        <div class="number-place">{{ code[2] }}</div>
        <div class="number-place">{{ code[3] }}</div>
      </div>
      <input id="input-number" v-model="rawCode" type="tel" maxlength="4" class="hidden-input" @input="check">
    </div>
    <div v-if="message.length !== 0" class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCodeInput',
  props: {
    received: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rawCode: '',
      code: '',
      focusNum: 0,
    }
  },
  watch: {
    received() {
      if (this.received.length === 4) {
        this.code = this.received
      }
    },
    rawCode() {
      this.rawCode = this.rawCode.replace(/[^0-9]/g, '')
      if (Number(this.rawCode[this.rawCode.length-1]) || this.rawCode[this.rawCode.length-1] === '0') {
        this.code = this.code.substr(0, this.rawCode.length-1) + this.rawCode[this.rawCode.length-1]
        if (this.rawCode.length === 0) {
          this.code = ''
        }
      }
    },
    code() {
      if (this.code.length === 4) {
        this.$emit('update:code', this.code)
      }
    },
  },
  mounted() {
    this.setFocus()
    document.getElementById('input-number').value = this.code
  },
  methods: {
    setFocus() {
      document.getElementById('input-number').focus()
    },
    check() {
      if (!document.getElementById('input-number').value) {
        this.code = ''
      }
      if (document.getElementById('input-number').value.length === 4) {
        this.code = document.getElementById('input-number').value
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-code-input {
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    color: red;
    text-align: center;
    font-size: 1.1em;
  }
  .hidden-input {
    z-index: -1;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  .code-inputs {
    margin: 0 0 18px;
    display: flex;
    align-items: center;

    .number-place {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      padding: 5px;
      margin: 0 3px;
      background-color: #ffffff;
      border: 1px solid rgba(0,0,0,0.12);
      box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 5px #CBCBE0;
      border-radius: 50%;
      font-size: 22px;
      font-weight: bold;
      color: black;
      &:placeholder-shown { border-bottom: 3px solid #E3000F; }
      &:focus-visible { outline: none; }
      &:focus { outline: none; }
      &::placeholder { opacity: 0; }
      &.filled {
        background-color: transparent;
        color: #000;
      }
    }
  }
}
</style>
