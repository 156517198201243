<template>
  <div class="scanner">
    <div class="aim-wrapper">
      <div v-show="abletoscan" class="aim">
        <n-icon icon="aim" />
      </div>
    </div>
  </div>
</template>

<script>

import { BarcodeScanner, } from '@capacitor-community/barcode-scanner'
export default {
  name: 'CScanner',
  props: {
    abletoscan: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    abletoscan() {
      if (this.abletoscan) {
        this.startScan()
      }
    },
  },
  mounted() {
  },
  beforeDestroy() {
    this.stopScan()
  },
  methods: {
    stopScan() {
      BarcodeScanner.showBackground()
      BarcodeScanner.stopScan()
    },
    startScan() {
      $n.startScan().then((result) => {
        if (result.length !== 0) {
          try {
            const obj = JSON.parse(result)
            this.$emit('scanned', obj)
          } catch (error) {
            alert('неверный qr')
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.scanner {
  width: 100vw;
  height: 90vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .aim-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .aim {
      z-index: -100;
      box-shadow: 0 4px 50px 500px rgba(9, 0, 0, 0.3);
      position: relative;
      width: 250px;
      height: 250px;
      border-radius: 12px;
      background: unset;
    }
  }
}
</style>
