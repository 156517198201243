import AppBar from 'components/app-bar/Index'
import CompanyLogo from 'components/company-logo/Index'
import CQr from 'components/qr/Qr'
import CScanner from 'components/scanner/Index'
import CCode from 'components/code-input/Index'
import CModalMessage from 'components/modal-message/Index'
import CGoBack from 'components/go-back/Index'
import CEmpty from 'components/empty/Index.vue'
import CSelectLoginType from 'components/select-login-type/Index.vue'

export default {
  AppBar,
  CompanyLogo,
  CQr,
  CScanner,
  CCode,
  CModalMessage,
  CGoBack,
  CEmpty,
  CSelectLoginType,
}

