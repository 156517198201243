<template>
  <div class="c-select-login-type">
    <div class="title">{{ title }}</div>
    <div class="types">
      <div v-for="(item, index) in types" :key="index" class="type" @click="selected = item">
        <div class="circle" :class="{'active': item.value === selected.value}" />
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CScanner',
  props: {
    title: {
      type: String,
      default: 'Выберите способ авторизации',
    },
    preSelected: {
      type: Object,
      default: () => {
        return { value: 'phone', title: 'Номер телефона', }
      },
    },
  },
  data() {
    return {
      selected: {},
      types: [
        { value: 'iin', title: 'ИИН', },
        { value: 'phone', title: 'Номер телефона', },
      ],
    }
  },
  watch: {
    selected() {
      this.$emit('update:selected', this.selected)
    },
  },
  mounted() {
    this.selected = this.preSelected
  },
}
</script>

<style lang="scss" scoped>
.c-select-login-type {
  .title {
    color: #686868;
    font-size: 14px;
    text-align: center;
  }
  .types {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 12px;
    .type {
      margin-right: 30px;
      color: #686868;
      font-size: 14px;
      display: flex;
      align-items: center;
      .circle {
        margin-right: 10px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #D9D9D9;
        &.active {
          background-color: var(--primary);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
