<template>
  <div class="c-empty">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'CEmpty',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {},
}
</script>

<style scoped lang="scss">
.c-empty {
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}
</style>
