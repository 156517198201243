import qr from 'assets/app-bar-icon/qr.svg'
import history from 'assets/app-bar-icon/history.svg'
import profile from 'assets/app-bar-icon/profile.svg'
import qrActive from 'assets/app-bar-icon/qrActive.svg'
import historyActive from 'assets/app-bar-icon/historyActive.svg'
import profileActive from 'assets/app-bar-icon/profileActive.svg'
import success from 'assets/success.svg'
import error from 'assets/icon-error.svg'
import password from 'assets/password.svg'
import logo from 'assets/icon-logo.svg'
import backRow from 'assets/back-row.svg'
import logout from 'assets/icon-logout.svg'
import aim from 'assets/aim.svg'
import arrowLeft from 'assets/arrow-left.svg'
import arrowRight from 'assets/arrow-right.svg'
import warning from 'assets/warn.svg'
import checklists from 'assets/app-bar-icon/checklists.svg'
import checklistsActive from 'assets/app-bar-icon/checklistsActive.svg'
import statsActive from 'assets/app-bar-icon/statsActive.svg'
import stats from 'assets/app-bar-icon/stats.svg'

export default {
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  qr,
  history,
  profile,
  qrActive,
  historyActive,
  profileActive,
  success,
  error,
  password,
  logo,
  backRow,
  logout,
  aim,
  warning,
  checklists,
  checklistsActive,
  stats,
  statsActive,
}
