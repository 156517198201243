<template>
  <div class="app-bar" :class="{'shadow': route !== 'index'}">
    <ul>
      <li v-if="$app.auth.user().readStats">
        <n-link class="round" :class="isRouteActive('stats') ? 'active' : ''" :to="{name: 'stats'}">
          <div class="img" :class="isRouteActive('stats') ? 'active' : ''">
            <n-icon :icon="isRouteActive('stats') ? 'statsActive' : 'stats'" />
          </div>
        </n-link>
      </li>
      <li>
        <n-link class="round" :class="isRouteActive('index') ? 'active' : ''" :to="{name: 'index'}">
          <div class="img" :class="isRouteActive('index') ? 'active' : ''">
            <n-icon :icon="isRouteActive('index') ? 'qrActive' : 'qr'" />
          </div>
        </n-link>
      </li>
      <li>
        <n-link class="round" :class="isRouteActive('history') ? 'active' : ''" :to="{name: 'history'}">
          <div class="img" :class="isRouteActive('history') ? 'active' : ''">
            <n-icon :icon="isRouteActive('history') ? 'historyActive' : 'history'" />
          </div>
        </n-link>
      </li>
      <!--      <li>-->
      <!--        <n-link class="round" :class="isRouteActive('checklists') ? 'active' : ''" :to="{name: 'checklists'}">-->
      <!--          <div class="img" :class="isRouteActive('checklists') ? 'active' : ''">-->
      <!--            <n-icon :icon="isRouteActive('checklists') ? 'checklistsActive' : 'checklists'"/>-->
      <!--          </div>-->
      <!--        </n-link>-->
      <!--      </li>-->
      <li>
        <n-link class="round" :class="isRouteActive('profile') ? 'active' : ''" :to="{name: 'profile'}">
          <div class="img" :class="isRouteActive('profile') ? 'active' : ''">
            <n-icon :icon="isRouteActive('profile') ? 'profileActive' : 'profile'" />
          </div>
        </n-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Index',
  computed: {
    route() {
      return this.$route.name
    },
  },
  created() {
    this.setShadow()
  },
  methods: {
    setShadow() {
      return '-5px -5px 10px rgba(193, 193, 193, 0.3), 5px 5px 10px rgba(193, 193, 193, 0.3), 10px 10px 20px rgba(193, 193, 193, 0.3), -10px -10px 20px rgba(193, 193, 193, 0.3)'
    },
    isRouteActive(name) {
      if (name === 'profile') {
        return [ 'profile', ].includes(this.route)
      }
      if (name === 'checklists') {
        return [ 'checklists', 'checklist', ].includes(this.route)
      }
      if (name === 'history') {
        return [ 'history', ].includes(this.route)
      }
      if (name === 'index') {
        return [ 'index', ].includes(this.route)
      }
      if (name === 'stats') {
        return [ 'stats', 'statsDepartments', 'statsDepartment', ].includes(this.route)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.app-bar {
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: calc(0px + env(safe-area-inset-bottom));
  left: 0;
  right: 0;
  background: #fff;
  z-index: 10;

  &.shadow {
    box-shadow: -5px -5px 10px rgba(193, 193, 193, 0.3), 5px 5px 10px rgba(193, 193, 193, 0.3), 10px 10px 20px rgba(193, 193, 193, 0.3), -10px -10px 20px rgba(193, 193, 193, 0.3);
  }

  &::after {
    border-radius: 20px 20px 0 0;
    content: '';
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    top: 0;
    background: #F5F5FA;
    z-index: -1;

  }

  ul {
    border-radius: 20px 20px 0 0;
    margin: 0;
    padding: 15px 0 15px 0;
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      .round {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #F5F5F9;
        box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(170, 170, 204, 0.25),
        10px 10px 20px rgba(170, 170, 204, 0.5), -10px -10px 20px #FFFFFF;

        &.active {
          background: #F5F5F9;
          box-shadow: inset -2.5px -2.5px 4px #FAFBFF, inset 2.5px 2.5px 4px #CBCBE0;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .img {
        height: 40px;
        width: 40px;
        padding: 8px;

        &.active {
          &::v-deep {
            .n-icon {
              path {
                fill: var(--primary) !important;
              }
            }
          }
        }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

}
</style>
