import BaseDate from 'nast-date'
import moment from 'moment-timezone'
import get from 'lodash/get'

/**
 *
 */
export default class NastDate extends BaseDate {
    // eslint-disable-next-line require-jsdoc
    format(datetime = undefined, type = undefined) {
        if (!type) {
            type = this._config('default')
        }
        const timezones = [ 'Asia/Nur-Sultan', 'Asia/Almaty', 'Asia/Astana', 'Asia/Qyzylorda', 'Asia/Aqtau', 'Asia/Oral', ]
        if (timezones.includes(Intl.DateTimeFormat().resolvedOptions().timeZone)) {
            datetime = moment.tz(datetime, 'Asia/Aqtobe')
        } else {
            datetime = moment(datetime)
        }
        if (!datetime._i) return ''
        return datetime.format(get(this._config('formats'), type, this._defaultFormat()))
    }
}
